<template>
	<div>
		<a-layout>
			<a-layout-sider class="content-left-menu" width="200px">
				<div style="padding: 30px 20px;">
						<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<a-icon :type="item.icon" theme="twoTone" />
							<span class="ft14 ml10">{{item.name}}</span>

						</div>					
				</div>
			</a-layout-sider>
			<a-layout-content class="ml15">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
		
	</div>
</template>

<script>
	export default {
		
		data() {
			return {
				showForm:false,
				order_num:0,
				menus: [
					{
						name: '全部',
						icon: 'profile',
						key: 'deliver_list',
						link: '/deliver'
					},
					{
						name: '待交付',
						icon: 'profile',
						key: 'deliver_deliver',
						link: '/deliver/deliver'
					},
					{
						name: '待修改',
						icon: 'appstore',
						key: 'deliver_modify',
						link: '/deliver/modify'
					},
					{
						name: '待反馈',
						icon: 'appstore',
						key: 'deliver_feedback',
						link: '/deliver/feedback'
					},
					{
						name: '没资料',
						icon: 'appstore',
						key: 'deliver_information',
						link: '/deliver/information'
					},
					{
						name: '部分制作',
						icon: 'appstore',
						key: 'deliver_part',
						link: '/deliver/part'
					},
					{
						name: '已完成',
						icon: 'appstore',
						key: 'deliver_complete',
						link: '/deliver/complete'
					}
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
		}
	}
</script>
